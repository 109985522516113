import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import RadioGroup from "@amzn/awsui-components-react/polaris/radio-group";
import { ColumnLayout } from "@amzn/awsui-components-react";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import ApiFactory from '../../open-api/ApiFactory';
import { getUser } from "../../auth/User";
import { PhoneToolImg } from "../Helper";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import ProgressBar from "@amzn/awsui-components-react/polaris/progress-bar";
import Select from "@amzn/awsui-components-react/polaris/select";

import { useCallback } from 'react';
interface RecordData {
    operator: string;
    securityGuard: string;
    accessType: 'PERSON-ONLY' | 'WITH-SPARE';
    roomInfo: string;
    status: 'IN' | 'OUT';
    tickets: string;
    serialNumberOrModel: string;
    checkTime: string;
    checkResult: 'PENDING' | 'APPROVED' | 'REJECTED';  // 移除 'PENDING'
}

interface SparePartImage {
    imageUrl: string;
    model: string;
    type: string;
    updateTime: string;
    updatedBy: string;
    approvedBy: string;
}

interface BulkResult {
    serialNumber: string;
    info: any;
    image: SparePartImage | null;
    error: string | null;
    checkResult: 'APPROVED' | 'REJECTED';
}


export default () => {
    const serialNumberInputRef = useRef<HTMLInputElement>(null);
    const [user, setUser] = useState('');
    const [errorText, setErrorText] = useState('');
    const [infoText, setInfoText] = useState("请依次输入信息");
    const [infoType, setInfoType] = useState<"error" | "success" | "info" | "warning">("info");
    const [sparePartImage, setSparePartImage] = useState<any>(null);
    const [localRecords, setLocalRecords] = useState<any[]>([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [partInfo, setPartInfo] = useState<any>(null);
    const [isPartUnderCustody, setIsPartUnderCustody] = useState(true);
    const Api = ApiFactory();
    const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
    const [bulkSerialNumbers, setBulkSerialNumbers] = useState('');
    const [bulkResults, setBulkResults] = useState<BulkResult[]>([]);
    const [bulkOperator, setBulkOperator] = useState('');
    const [bulkRoomInfo, setBulkRoomInfo] = useState('');
    const [bulkStatus, setBulkStatus] = useState<'IN' | 'OUT'>('IN');
    const [bulkTickets, setBulkTickets] = useState('');
    const [validationProgress, setValidationProgress] = useState(0);
    const [selectedTypeImage, setSelectedTypeImage] = useState<SparePartImage | null>(null);
    // 在组件顶部添加以下状态
    const [typeOptions, setTypeOptions] = useState<{ label: string; value: string }[]>([]);
    const [selectedType, setSelectedType] = useState<string | null>(null);


    // 在useEffect中添加以下代码来获取type_name列表
    useEffect(() => {
        const fetchTypeOptions = async () => {
            try {
                const response = await Api.listSparePartImages();
                const approvedImages = response.data.sparePartImages.filter(isFullyApproved);
                const uniqueTypes = [...new Set(approvedImages.map(img => img.type))];
                setTypeOptions(uniqueTypes.map(type => ({ label: type, value: type })));
            } catch (error) {
                console.error("Error fetching type options:", error);
            }
        };

        fetchTypeOptions();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            } catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);

    
    const initialRecordData: RecordData = {
        operator: '',
        securityGuard: user,
        accessType: 'PERSON-ONLY',
        roomInfo: '',
        status: 'IN',
        tickets: '',
        serialNumberOrModel: '',
        checkTime: new Date().toISOString(),
        checkResult: 'PENDING'
    };
    const ScansApi = ApiFactory();

    const [recordData, setRecordData] = useState<RecordData>(initialRecordData);

    // 在表单中使用时
    const onChange = (attribute: string, value: string) => {
        const newState = { ...recordData };
        newState[attribute] = value;
        setRecordData(newState);

        // 如果是设备型号发生变化，且选择了带设备进入，则查询对应的图片
        if (attribute === 'serialNumberOrModel' && 
            recordData.accessType === 'WITH-SPARE' && 
            value) {
            checkSparePartImage(value, recordData.operator);
        }
    };

    const hasRedValue = (info: any): boolean => {
        return Object.values(info).some(value => 
            value === 'Parts Not Under Custody' || value === 'Parts Not In Mobility'
        );
    };


    const handleBulkValidation = async () => {
        if (!bulkOperator || !bulkRoomInfo || !selectedType) {
            setErrorText('请填写必要信息并选择备件类型');
            return;
        }
    
        const serialNumbers = bulkSerialNumbers.split('\n').filter(sn => sn.trim());
        const results: BulkResult[] = [];
        
        setValidationProgress(0);
    
        for (let i = 0; i < serialNumbers.length; i++) {
            const sn = serialNumbers[i];
            try {
                const response = await ScansApi.checkSN(sn.trim(), bulkOperator);
                const result: BulkResult = {
                    serialNumber: sn.trim(),
                    info: response.data,
                    image: null,
                    error: null,
                    checkResult: 'APPROVED' // 默认设置为允许
                };
    
                if (response.data && response.data['type_name']) {
                    const type_name = response.data['type_name'].replace(/\s+/g, '-');
                    const model_name = response.data['model_name'].replace(/\s+/g, '-');
    
                    if (type_name !== selectedType) {
                        result.error = `备件类型不匹配，期望: ${selectedType}，实际: ${type_name}`;
                        result.checkResult = 'REJECTED';
                    } else if (response.data['messages'] === 'Parts Not In Mobility') {
                        result.error = 'Parts Not In Mobility';
                        result.checkResult = 'REJECTED';
                    } else {
                        const imageResponse = await Api.listSparePartImages();
                        const approvedImages = imageResponse.data.sparePartImages.filter(isFullyApproved);
    
                        let matchingImage = approvedImages.find(
                            img => img.type === type_name && (img.model === model_name || img.model === type_name)
                        );
    
                        if (matchingImage) {
                            result.image = matchingImage;
                        }
                    }
                } else {
                    result.error = '备件不存在';
                    result.checkResult = 'REJECTED';
                }
    
                results.push(result);
            } catch (error) {
                results.push({
                    serialNumber: sn.trim(),
                    info: null,
                    image: null,
                    error: "查询失败",
                    checkResult: 'REJECTED'
                });
            }
    
            setValidationProgress(((i + 1) / serialNumbers.length) * 100);
        }
    
        setBulkResults(results);
    };

    const handleCheckResultChange = (index: number, value: 'APPROVED' | 'REJECTED') => {
        setBulkResults(prevResults => {
            const newResults = [...prevResults];
            newResults[index].checkResult = value;
            return newResults;
        });
    };

    const handleBulkSubmit = async () => {
        for (const result of bulkResults) {
            if (!result.error) {
                const requestContent = {
                    operator: bulkOperator,
                    securityGuard: user,
                    accessType: 'WITH-SPARE',
                    roomInfo: bulkRoomInfo,
                    status: bulkStatus,
                    tickets: bulkTickets,
                    serialNumberOrModel: result.serialNumber,
                    checkTime: new Date().toISOString(),
                    checkResult: result.checkResult
                };

                try {
                    const response = await Api.createRoomAccessRecord(requestContent);
                    setLocalRecords(prev => [response.data.record, ...prev]);
                } catch (error: any) {
                    setErrorText(error?.response?.data?.message || '创建记录失败');
                }
            }
        }

        setIsBulkModalVisible(false);
        setInfoType("success");
        setInfoText("批量记录创建成功");
    };

    const checkSparePartImage = async (serialNumber: string, operator: string) => {
        try {
            // 首先获取备件信息
            const partInfoResponse = await ScansApi.checkSN(serialNumber, operator);
            console.log(partInfoResponse);
            setPartInfo(partInfoResponse.data);
    
            // 检查备件是否在监管之下
            if (partInfoResponse.data['messages'] === "Parts Not Under Custody") {
                setIsPartUnderCustody(false);
                setInfoType("warning");
                setInfoText("备件不在监管之下，请确保输入了有效的 Ticket");
            } else {
                setIsPartUnderCustody(true);
            }
    
            if (!partInfoResponse.data || !partInfoResponse.data['type_name']) {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText("无法获取备件信息");
                return;
            }
    
            const type_name = partInfoResponse.data['type_name'].replace(/\s+/g, '-');
            const model_name = partInfoResponse.data['model_name'].replace(/\s+/g, '-');
    
            // 获取所有图片
            const response = await Api.listSparePartImages();
            const approvedImages = response.data.sparePartImages.filter(isFullyApproved);
    
            // 首先尝试找到完全匹配的图片（type和model都匹配）
            let matchingImage = approvedImages.find(
                img => img.type === type_name && img.model === model_name
            );
    
            // 如果没有找到完全匹配的，尝试找Generic型号的图片
            if (!matchingImage) {
                matchingImage = approvedImages.find(
                    img => img.type === type_name && img.model === type_name
                );
            }
    
            if (matchingImage) {
                setSparePartImage(matchingImage);
                // setInfoType("success");
                // setInfoText(
                //     matchingImage.model === 'Generic' 
                //         ? `找到对应类型(${type_name})的通用已批准备件图片` 
                //         : `找到完全匹配的已批准备件图片`
                // );
            } else {
                setSparePartImage(null);
                setInfoType("error");
                setInfoText(`未找到类型为 ${type_name} 的已批准备件图片`);
            }
    
        } catch (error) {
            console.error("Error checking spare part image:", error);
            setSparePartImage(null);
            setInfoType("error");
            setInfoText("查询备件图片时发生错误");
        }
    };
    const handleTypeSelection = async (selectedType: string) => {
        setSelectedType(selectedType);
        try {
            const imageResponse = await Api.listSparePartImages();
            const approvedImages = imageResponse.data.sparePartImages.filter(isFullyApproved);
            const matchingImage = approvedImages.find(img => img.type === selectedType && img.model === selectedType);
            setSelectedTypeImage(matchingImage || null);
        } catch (error) {
            console.error("Error fetching type image:", error);
            setSelectedTypeImage(null);
        }
    };

    const renderValue = (value: unknown): React.ReactNode => {
        if (typeof value === 'string') {
            if (value === 'Parts Not Under Custody' || value === 'Parts Not In Mobility') {
                return <Box color="text-status-error">{value}</Box>;
            }
            return value;
        } else if (typeof value === 'number' || typeof value === 'boolean') {
            return String(value);
        } else if (value === null) {
            return 'null';
        } else if (value === undefined) {
            return 'undefined';
        } else if (Array.isArray(value)) {
            return JSON.stringify(value);
        } else if (typeof value === 'object') {
            return JSON.stringify(value);
        }
        return 'Unknown type';
    };
    
    // 辅助函数来检查是否完全批准
    const isFullyApproved = (image: any) => {
        const approvers = image.approvedBy ? image.approvedBy.split(',').filter(Boolean) : [];
        return approvers.length >= 2;
    };

    const handleSubmit = async () => {
        setErrorText('');
        try {
            if (!recordData.operator || !recordData.roomInfo) {
                setErrorText('请填写必要信息');
                return;
            }
    
            if (recordData.accessType === 'WITH-SPARE') {
                if (!recordData.serialNumberOrModel) {
                    setErrorText('带入设备时必须填写设备型号');
                    return;
                }
                await checkSparePartImage(recordData.serialNumberOrModel, recordData.operator);
                
                if (!isPartUnderCustody && !recordData.tickets) {
                    setErrorText('备件不在监管之下，必须填写 Ticket');
                    return;
                }
            }
    
            // 显示 Modal 进行检查结果选择
            setIsModalVisible(true);
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建记录失败');
        }
    };


    const handleModalSubmit = async (checkResult: 'APPROVED' | 'REJECTED') => {
        try {
            const requestContent = {
                operator: recordData.operator,
                securityGuard: user,
                accessType: recordData.accessType,
                roomInfo: recordData.roomInfo,
                status: recordData.status,
                tickets: recordData.tickets || '',
                serialNumberOrModel: recordData.serialNumberOrModel || '',
                checkTime: new Date().toISOString(),
                checkResult: checkResult
            };
    
            const response = await Api.createRoomAccessRecord(requestContent);
            
            setLocalRecords(prev => [response.data.record, ...prev]);
    
            setInfoType("success");
            setInfoText(`记录创建成功 - ${checkResult === 'APPROVED' ? '允许' : '拒绝'}进入`);
            
            setRecordData(prev => ({
                ...prev,
                tickets: '',
                serialNumberOrModel: '',
                checkTime: new Date().toISOString(),
                checkResult: checkResult
            }));

            if (serialNumberInputRef.current) {
                serialNumberInputRef.current.focus();
            }

            setIsModalVisible(false);
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建记录失败');
        }
    };

    const columnDefinitions = [
        {
            id: "operator",
            header: "操作人员",
            cell: item => (
                <SpaceBetween direction="horizontal" size="xs">
                    {item.operator}
                    {PhoneToolImg(item.operator)}
                </SpaceBetween>
            )
        },
        {
            id: "securityGuard",
            header: "安保人员",
            cell: item => item.securityGuard
        },
        {
            id: "accessType",
            header: "进出类型",
            cell: item => item.accessType === 'PERSON-ONLY' ? '人员进出' : '携带备件'
        },
        {
            id: "roomInfo",
            header: "房间信息",
            cell: item => item.roomInfo
        },
        {
            id: "status",
            header: "进出状态",
            cell: item => item.status === 'IN' ? '进入' : '出去'
        },
        {
            id: "serialNumberOrModel",
            header: "设备型号/序列号",
            cell: item => item.serialNumberOrModel || '-'
        },
        {
            id: "tickets",
            header: "Tickets",
            cell: item => item.tickets || '-'
        },
        {
            id: "checkTime",
            header: "检查时间",
            cell: item => item.checkTime
        },
        {
            id: "checkResult",
            header: "检查结果",
            cell: item => {
                switch(item.checkResult) {
                    case 'APPROVED': 
                        return <Box color="text-status-success">允许</Box>;
                    case 'REJECTED': 
                        return <Box color="text-status-error">拒绝</Box>;
                    default: 
                        return <Box color="text-status-info">待处理</Box>;
                }
            }
        }
    ];

    return (
        <>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" variant="link" href="#/room-access">
                            取消
                        </Button>
                        <Button variant="primary" onClick={handleSubmit}>
                            提交
                        </Button>

                    </SpaceBetween>
                }
                header={
                    <>
                        <Header variant="h1">机房进出登记</Header>
                        <Header variant="h3">安保人员：{user}</Header>
                    </>
                }
                errorText={errorText}
            >
                <Container>
                    <Alert
                        statusIconAriaLabel="Info"
                        type={infoType}
                        header={infoText}
                    />
                    <SpaceBetween direction="vertical" size="l">
                        <ColumnLayout columns={2}>
                            <FormField 
                                    label={
                                        <span>
                                            操作人员 {recordData.operator && PhoneToolImg(recordData.operator)}
                                        </span>
                                    } 
                                    description="进入人员的登录名"
                                >
                                <Input
                                    value={recordData.operator}
                                    onChange={({ detail }) => onChange('operator', detail.value)}
                                    placeholder="请输入操作人员ID"
                                />
                            </FormField>
                            <FormField label="进出类型">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('accessType', detail.value as 'PERSON-ONLY' | 'WITH-SPARE')}
                                    value={recordData.accessType}
                                    items={[
                                        { value: 'PERSON-ONLY', label: '人员进出' },
                                        { value: 'WITH-SPARE', label: '携带备件' }
                                    ]}
                                />
                            </FormField>
                            <FormField label="房间信息">
                                <Input
                                    value={recordData.roomInfo}
                                    onChange={({ detail }) => onChange('roomInfo', detail.value)}
                                    placeholder="请输入房间信息"
                                />
                            </FormField>
                            <FormField label="进出状态">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('status', detail.value as 'IN' | 'OUT')}
                                    value={recordData.status}
                                    items={[
                                        { value: 'IN', label: '进入' },
                                        { value: 'OUT', label: '出去' }
                                    ]}
                                />
                            </FormField>
                            {/* <FormField label="检查结果">
                                <RadioGroup
                                    onChange={({ detail }) => onChange('checkResult', detail.value as 'APPROVED' | 'REJECTED')}
                                    value={recordData.checkResult}
                                    items={[
                                        { value: 'APPROVED', label: '允许' },
                                        { value: 'REJECTED', label: '拒绝' },
                                        { value: 'PENDING', label: '待处理' }
                                    ]}
                                />
                            </FormField> */}
                            {recordData.accessType === 'WITH-SPARE' && (
                                <>
                                    <FormField label="设备型号/序列号">
                                        <Input
                                            value={recordData.serialNumberOrModel}
                                            onChange={({ detail }) => onChange('serialNumberOrModel', detail.value)}
                                            placeholder="请输入设备型号或序列号"
                                        />
                                    </FormField>
                                    <FormField label="Tickets">
                                        <Input
                                            value={recordData.tickets}
                                            onChange={({ detail }) => onChange('tickets', detail.value)}
                                            placeholder="请输入相关Ticket"
                                        />
                                    </FormField>
                                </>
                            )}
                        </ColumnLayout>

                        {sparePartImage && (
                            <Container header={<Header variant="h2">已批准的备件图片信息</Header>}>
                                <Alert type="success" header="此备件图片已完全批准">
                                    该备件已获得至少两人的批准。
                                </Alert>
                                <img 
                                    src={sparePartImage.imageUrl} 
                                    alt="Approved Spare Part" 
                                    style={{maxWidth: '100%', maxHeight: '300px'}} 
                                />
                                <Table
                                    columnDefinitions={[
                                        { header: "型号", cell: item => item.model },
                                        { header: "类型", cell: item => item.type },
                                        { header: "更新时间", cell: item => item.updateTime },
                                        { header: "更新人", cell: item => item.updatedBy },
                                        { header: "批准人", cell: item => item.approvedBy }
                                    ]}
                                    items={[sparePartImage]}
                                />
                            </Container>
                        )}
                    </SpaceBetween>
                </Container>
            </Form>
            <Button onClick={() => setIsBulkModalVisible(true)}>
                批量验证
            </Button>

            <Modal
                onDismiss={() => setIsBulkModalVisible(false)}
                visible={isBulkModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsBulkModalVisible(false)}>关闭</Button>
                            <Button variant="primary" onClick={handleBulkValidation}>验证</Button>
                            {bulkResults.length > 0 && (
                                <Button variant="primary" onClick={handleBulkSubmit}>提交</Button>
                            )}
                        </SpaceBetween>
                    </Box>
                }
                header="批量验证备件"
            >
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="操作人员">
                        <Input
                            value={bulkOperator}
                            onChange={({ detail }) => setBulkOperator(detail.value)}
                            placeholder="请输入操作人员ID"
                        />
                    </FormField>
                    <FormField label="房间信息">
                        <Input
                            value={bulkRoomInfo}
                            onChange={({ detail }) => setBulkRoomInfo(detail.value)}
                            placeholder="请输入房间信息"
                        />
                    </FormField>
                    <FormField label="进出状态">
                        <RadioGroup
                            onChange={({ detail }) => setBulkStatus(detail.value as 'IN' | 'OUT')}
                            value={bulkStatus}
                            items={[
                                { value: 'IN', label: '进入' },
                                { value: 'OUT', label: '出去' }
                            ]}
                        />
                    </FormField>
                    <FormField label="Tickets">
                        <Input
                            value={bulkTickets}
                            onChange={({ detail }) => setBulkTickets(detail.value)}
                            placeholder="请输入相关Ticket"
                        />
                    </FormField>
                    <FormField label="备件类型">
                        <Select
                            selectedOption={selectedType ? { label: selectedType, value: selectedType } : null}
                            onChange={({ detail }) => handleTypeSelection(detail.selectedOption.value as string)}
                            options={typeOptions}
                            placeholder="选择备件类型"
                        />
                    </FormField>

                    {selectedTypeImage && (
                        <Container header={<Header variant="h2">已批准的备件图片</Header>}>
                            <img 
                                src={selectedTypeImage.imageUrl} 
                                alt="Approved Spare Part" 
                                style={{maxWidth: '100%', maxHeight: '300px'}} 
                            />
                            <Table
                                columnDefinitions={[
                                    { header: "型号", cell: item => item.model },
                                    { header: "类型", cell: item => item.type },
                                    { header: "更新时间", cell: item => item.updateTime },
                                    { header: "更新人", cell: item => item.updatedBy },
                                    { header: "批准人", cell: item => item.approvedBy }
                                ]}
                                items={[selectedTypeImage]}
                            />
                        </Container>
                    )}
                    <FormField label="输入多个序列号（每行一个）">
                        <Textarea
                            value={bulkSerialNumbers}
                            onChange={({ detail }) => setBulkSerialNumbers(detail.value)}
                            rows={5}
                        />
                    </FormField>
                    {validationProgress > 0 && validationProgress < 100 && (
                        <ProgressBar
                            value={validationProgress}
                            label="验证进度"
                            description={`${Math.round(validationProgress)}%`}
                        />
                    )}

                    {bulkResults.map((result, index) => (
                        <Container key={index} header={<Header variant="h3">{result.serialNumber}</Header>}>
                            {result.error ? (
                                <Alert type="error" header={result.error} />
                            ) : (
                                <>
                                    {/* <Table
                                        columnDefinitions={[
                                            { header: "属性", cell: (item) => item.key },
                                            { header: "值", cell: (item) => renderValue(item.value) }
                                        ]}
                                        items={Object.entries(result.info).map(([key, value]) => ({ key, value }))}
                                    /> */}
                                    {result.image && (
                                        <>
                                            {/* <Header>已批准的备件图片</Header>
                                            <img 
                                                src={result.image.imageUrl} 
                                                alt="Approved Spare Part" 
                                                style={{maxWidth: '100%', maxHeight: '200px'}} 
                                            /> */}
                                        </>
                                    )}
                                </>
                            )}
                            <FormField label="检查结果">
                                <Select
                                    selectedOption={{ label: result.checkResult === 'APPROVED' ? '允许' : '拒绝', value: result.checkResult }}
                                    onChange={({ detail }) => handleCheckResultChange(index, detail.selectedOption.value as 'APPROVED' | 'REJECTED')}
                                    options={[
                                        { label: '允许', value: 'APPROVED' },
                                        { label: '拒绝', value: 'REJECTED' }
                                    ]}
                                />
                            </FormField>
                        </Container>
                    ))}
                </SpaceBetween>
            </Modal>

            <Modal
                onDismiss={() => setIsModalVisible(false)}
                visible={isModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => setIsModalVisible(false)}>取消</Button>
                            <Button variant="primary" onClick={() => handleModalSubmit('APPROVED')}>允许进入</Button>
                            <Button variant="primary" onClick={() => handleModalSubmit('REJECTED')}>拒绝进入</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="检查结果"
            >
                <SpaceBetween direction="vertical" size="l">
                    <Box>请选择检查结果：</Box>
                    {recordData.accessType === 'WITH-SPARE' && (
                        <>
                            {!isPartUnderCustody && (
                                <Alert type="warning" header="备件不在监管之下" action={<Button href={"https://t.corp.amazon.com/"+recordData.tickets} target="_blank" iconName="external">Open</Button>}>
                                    {recordData.tickets ? "已输入: ticket" : "请确保输入了有效的 Ticket"}
                                </Alert>
                            )}
                            {partInfo && (
                                <Container header={<Header variant="h2">备件信息</Header>}>
                                    <Table
                                        columnDefinitions={[
                                            { header: "属性", cell: (item) => item.key },
                                            { header: "值", cell: (item) => renderValue(item.value) }
                                        ]}
                                        items={Object.entries(partInfo).map(([key, value]) => ({ key, value }))}
                                    />
                                </Container>
                            )}
                            {sparePartImage && (
                                <Container header={<Header variant="h2">已批准的备件图片信息</Header>}>
                                    <Alert type="success" header="此备件图片已完全批准">
                                        该备件已获得至少两人的批准。
                                    </Alert>
                                    <img 
                                        src={sparePartImage.imageUrl} 
                                        alt="Approved Spare Part" 
                                        style={{maxWidth: '100%', maxHeight: '300px'}} 
                                    />
                                    <Table
                                        columnDefinitions={[
                                            { header: "型号", cell: item => item.model },
                                            { header: "类型", cell: item => item.type },
                                            { header: "更新时间", cell: item => item.updateTime },
                                            { header: "更新人", cell: item => item.updatedBy },
                                            { header: "批准人", cell: item => item.approvedBy }
                                        ]}
                                        items={[sparePartImage]}
                                    />
                                </Container>
                            )}
                        </>
                    )}
                </SpaceBetween>
            </Modal>

            {localRecords.length > 0 && (
                <Container
                    header={
                        <Header
                            variant="h2"
                            counter={`(${localRecords.length})`}
                        >
                            本次扫描记录
                        </Header>
                    }
                >
                    <Table
                        columnDefinitions={columnDefinitions}
                        items={localRecords}
                        loading={false}
                        loadingText="Loading records"
                        sortingDisabled
                        variant="embedded"
                        empty={
                            <Box textAlign="center" color="inherit">
                                <b>无记录</b>
                                <Box
                                    padding={{ bottom: "s" }}
                                    variant="p"
                                    color="inherit"
                                >
                                    暂无扫描记录
                                </Box>
                            </Box>
                        }
                    />
                </Container>
            )}
            
        </>
    );
};
import SideNavigation, {SideNavigationProps} from '@amzn/awsui-components-react/polaris/side-navigation';
import BreadcrumbGroup, {BreadcrumbGroupProps} from '@amzn/awsui-components-react/polaris/breadcrumb-group';
import {useHistory, useLocation} from 'react-router-dom';

const HOME = {text: 'BJS CICO Assistants', href: '#/'};
const items: SideNavigationProps.Item[] = [
    {
        type: 'section',
        text: '备件验证',
        items: [
            {type: 'link', text: '主页', href: '#/home'},
            {type: 'link', text: '备件进入检查', href: '#/scan'},
            {type: 'link', text: '备件图片', href: '#/spare/list'},
        ]
    },
    {
        type: 'section',
        text: '工具验证',
        items: [
            {type: 'link', text: '工具检查', href: '#/tools'},
            {type: 'link', text: 'CICO Exception', href: '#/exception'},
            {type: 'link', text: 'CAT', href: '#/cat'},
        ]
    },
    {
        type: 'section',
        text: '其他',
        items: [
            {type: 'link', text: '记录查询和导出', href: '#/listrecords'},
            {type: 'link', text: 'CAT进机房记录查询和导出', href: '#/records'},
        ]
    }
];

export function Breadcrumbs({items}: { items: BreadcrumbGroupProps.Item[] }) {
    return <BreadcrumbGroup items={[HOME, ...items]}/>;
}

// ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
export function ServiceNavigation() {
    // If the provided link is empty, do not redirect pages

    const location = useLocation();
    const navigate = useHistory();

    const onFollowHandler = (event: CustomEvent<SideNavigationProps.FollowDetail>) => {
        event.preventDefault();
        if (event.detail.href) {
            console.log(location)
            console.log(event.detail.href)
            console.log(navigate)
            window.location.href = event.detail.href
        }
    };

    return <SideNavigation header={HOME} items={items} activeHref={`#${location.pathname}`}
                           onFollow={onFollowHandler}/>;
}
import AppLayout from '@amzn/awsui-components-react/polaris/app-layout';
import ToolsList from './ToolsList';
import {Breadcrumbs, ServiceNavigation} from '../navigation/navigation'


export default function ToolsListPage() {
    return (
        <AppLayout
            content={<ToolsList />}
            contentType="default"
            navigationHide={false}
            navigation={<ServiceNavigation />}
            toolsHide={true}
            disableContentPaddings={false}
        />
    );
}
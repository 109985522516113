import * as React from "react";
import { useState, useEffect } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import ButtonDropdown from "@amzn/awsui-components-react/polaris/button-dropdown";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import CollectionPreferences from "@amzn/awsui-components-react/polaris/collection-preferences";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import Form from "@amzn/awsui-components-react/polaris/form";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ApiFactory from '../../open-api/ApiFactory';
import { SparePartImage, SparePartImageCreationInput, UploadSparePartImageRequestContent } from '../../open-api/generated-src';
import { getUser } from "../../auth/User";

export default () => {
  const [selectedItems, setSelectedItems] = useState<SparePartImage[]>([]);
  const [sparePartImages, setSparePartImages] = useState<SparePartImage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState<SparePartImage | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [newSparePartImage, setNewSparePartImage] = useState<Partial<SparePartImageCreationInput>>({});
  const [errorText, setErrorText] = useState('');
  const [approveErrorText, setApproveErrorText] = useState('');
  const [approveSuccessMessage, setApproveSuccessMessage] = useState('');
  const [user, setUser] = useState('');
  const [preferences, setPreferences] = useState<{ pageSize: number }>({ pageSize: 10 });
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [filteringText, setFilteringText] = useState('');

  const SparePartImageApi = ApiFactory();
  // 添加新的 state
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<SparePartImage | null>(null);
  const [deleteError, setDeleteError] = useState('');
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  // 添加删除相关的处理函数
  const handleDeleteClick = (item: SparePartImage) => {
    setItemToDelete(item);
    setIsDeleteModalVisible(true);
    setDeleteError('');
    setDeleteSuccess(false);
  };

  const handleDelete = async () => {
    if (!itemToDelete) return;

    try {
      await SparePartImageApi.deleteSparePartImage(
        itemToDelete.model,
        itemToDelete.type
      );

      // 从列表中移除已删除的项
      setSparePartImages(prevImages => 
        prevImages.filter(img => 
          !(img.model === itemToDelete.model && img.type === itemToDelete.type)
        )
      );

      setDeleteSuccess(true);
      
      // 3秒后关闭对话框
      setTimeout(() => {
        setIsDeleteModalVisible(false);
        setItemToDelete(null);
        setDeleteSuccess(false);
      }, 3000);

    } catch (error: any) {
      setDeleteError(error?.response?.data?.message || 'Failed to delete spare part image');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setUser(await getUser());
      } catch(error: any) {
        setUser('Unknown User')
      }
    })();
  }, []);

  useEffect(() => {
    const fetchSparePartImages = async () => {
      try {
        const response = await SparePartImageApi.listSparePartImages();
        setSparePartImages(response.data.sparePartImages);
      } catch (error) {
        console.error("Error fetching spare part images:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchSparePartImages();
  }, []);

  const handleCreateSparePartImage = async () => {
    setErrorText('');
    try {
      if (newSparePartImage.model && newSparePartImage.type && uploadedFile) {
        const currentTime = new Date().toISOString();
        const sparePartImageCreationInput: SparePartImageCreationInput = {
          model: newSparePartImage.model,
          type: newSparePartImage.type,
          updateTime: currentTime,
          updatedBy: user,
          approvedBy: '',
          fileContent: await convertFileToBase64(uploadedFile),
          fileName: uploadedFile.name
        };

        const uploadRequestContent: UploadSparePartImageRequestContent = {
          sparePartImage: sparePartImageCreationInput
        };

        await SparePartImageApi.uploadSparePartImage(uploadRequestContent);

        // Refresh the list
        const response = await SparePartImageApi.listSparePartImages();
        setSparePartImages(response.data.sparePartImages);

        setIsModalVisible(false);
        setNewSparePartImage({});
        setUploadedFile(null);
      } else {
        setErrorText('Please enter model, type and upload an image');
      }
    } catch(error: any) {
      setErrorText(error?.response?.data?.message || 'Unknown error');
    }
  };

  const handleApprove = async (item: SparePartImage) => {
    setSelectedImage(item);
    setIsApproveModalVisible(true);
    setApproveErrorText('');
    setApproveSuccessMessage('');
  };

  const submitApproval = async () => {
    try {
      if (!selectedImage) return;

      const currentApprovers = selectedImage.approvedBy ? selectedImage.approvedBy.split(',').filter(Boolean) : [];
      
      if (currentApprovers.includes(user)) {
        setApproveErrorText('You have already approved this image');
        return;
      }

      const newApprovers = [...currentApprovers, user];
      
      const updateRequestContent = {
        model: selectedImage.model,
        type: selectedImage.type,
        updateTime: new Date().toISOString(),
        updatedBy: user,
        approvedBy: newApprovers.join(','),
        fileContent: '',
        fileName: ''
      };

      // 修改这里的 API 调用，提供所有必需的参数
      await SparePartImageApi.updateSparePartImage(
        selectedImage.model,
        selectedImage.type,
        updateRequestContent
      );

      const response = await SparePartImageApi.listSparePartImages();
      setSparePartImages(response.data.sparePartImages);

      setApproveSuccessMessage('Approval submitted successfully');
      
      if (newApprovers.length >= 2) {
        setApproveSuccessMessage('Approval process completed with two approvers');
      }

      setTimeout(() => {
        setIsApproveModalVisible(false);
        setApproveSuccessMessage('');
      }, 3000);

    } catch (error: any) {
      setApproveErrorText(error?.response?.data?.message || 'Failed to submit approval');
    }
  };

  function convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const renderApprovalStatus = (item: SparePartImage) => {
    const approvers = item.approvedBy ? item.approvedBy.split(',').filter(Boolean) : [];
    const approvalCount = approvers.length;
    const isFullyApproved = approvalCount >= 2;
    const hasUserApproved = approvers.includes(user);

    return (
      <SpaceBetween direction="vertical" size="xs">
        <div>Approvers: {approvers.join(', ') || 'None'}</div>
        <div>Status: {isFullyApproved ? 'Fully Approved' : `${approvalCount}/2 Approvals`}</div>
        {!isFullyApproved && !hasUserApproved && (
          <Button
            variant="primary"
            onClick={() => handleApprove(item)}
          >
            Approve
          </Button>
        )}
      </SpaceBetween>
    );
  };

  const createSparePartImageButton = (
    <Button variant="primary" onClick={() => setIsModalVisible(true)}>
      Create Spare Part Image
    </Button>
  );

  const handleViewDetails = (item: SparePartImage) => {
    setSelectedImage(item);
    setIsDetailsModalVisible(true);
  };

  const filteredItems = React.useMemo(() => {
    if (!filteringText) return sparePartImages;
    const lowerFilteringText = filteringText.toLowerCase();
    return sparePartImages.filter(item => 
      item.model.toLowerCase().includes(lowerFilteringText) ||
      item.type.toLowerCase().includes(lowerFilteringText) ||
      item.updatedBy.toLowerCase().includes(lowerFilteringText)
    );
  }, [sparePartImages, filteringText]);

  const paginatedItems = React.useMemo(() => {
    const startIndex = (currentPageIndex - 1) * preferences.pageSize;
    const endIndex = startIndex + preferences.pageSize;
    return filteredItems.slice(startIndex, endIndex);
  }, [filteredItems, currentPageIndex, preferences.pageSize]);

  return (
    <>
      <Table
        renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
          `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
        }
        onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
        selectedItems={selectedItems}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: () => "select all",
          itemSelectionLabel: ({ selectedItems }, item) => `${item.model} ${item.type}`
        }}
        columnDefinitions={[
          {
            id: "model",
            header: "Model",
            cell: item => item.model,
            sortingField: "model"
          },
          {
            id: "type",
            header: "Type",
            cell: item => item.type,
            sortingField: "type"
          },
          {
            id: "updateTime",
            header: "Update Time",
            cell: item => item.updateTime
          },
          {
            id: "updatedBy",
            header: "Updated By",
            cell: item => item.updatedBy
          },
          {
            id: "approvalStatus",
            header: "Approval Status",
            cell: item => renderApprovalStatus(item)
          },
          {
            id: "actions",
            header: "Actions",
            cell: (item: SparePartImage) => (
              <SpaceBetween direction="vertical" size="xs">
                <Button
                  onClick={() => handleViewDetails(item)}
                >
                  View Details
                </Button>
                <Button
                  onClick={() => handleDeleteClick(item)}
                >
                  Delete
                </Button>
              </SpaceBetween>
            )
          }
        ]}
        items={paginatedItems}
        loading={loading}
        loadingText="Loading spare part images"
        selectionType="single"
        header={
          <Header
            counter={`(${filteredItems.length})`}
            actions={<SpaceBetween direction="horizontal" size="xs">
              {createSparePartImageButton}
            </SpaceBetween>}
          >
            Spare Part Images
          </Header>
        }
        filter={
          <TextFilter
            filteringPlaceholder="Find spare part images"
            filteringText={filteringText}
            onChange={({ detail }) => setFilteringText(detail.filteringText)}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPageIndex}
            onChange={({ detail }) => setCurrentPageIndex(detail.currentPageIndex)}
            pagesCount={Math.ceil(filteredItems.length / preferences.pageSize)}
          />
        }
        preferences={
          // 修改 CollectionPreferences 组件的 onConfirm 处理
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences({ pageSize: detail.pageSize || 10 })}
            pageSizePreference={{
              title: "Page size",
              options: [
                { value: 10, label: "10 images" },
                { value: 20, label: "20 images" }
              ]
            }}
          />
        }
      />

      {/* Create Modal */}
      <Modal
        onDismiss={() => setIsModalVisible(false)}
        visible={isModalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setIsModalVisible(false)}>Cancel</Button>
              <Button variant="primary" onClick={handleCreateSparePartImage}>Create</Button>
            </SpaceBetween>
          </Box>
        }
        header="Create Spare Part Image"
      >
        <Form errorText={errorText}>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Model">
              <Input
                value={newSparePartImage.model || ''}
                onChange={({ detail }) => setNewSparePartImage(prev => ({ ...prev, model: detail.value }))}
              />
            </FormField>
            <FormField label="Type">
              <Input
                value={newSparePartImage.type || ''}
                onChange={({ detail }) => setNewSparePartImage(prev => ({ ...prev, type: detail.value }))}
              />
            </FormField>
            <FormField label="Upload Image">
              <FileUpload
                onChange={({ detail }) => setUploadedFile(detail.value[0])}
                value={uploadedFile ? [uploadedFile] : []}
                i18nStrings={{
                  uploadButtonText: e => e ? "Choose files" : "Choose file",
                  dropzoneText: e => e ? "Drop files to upload" : "Drop file to upload",
                  removeFileAriaLabel: e => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error"
                }}
                accept="image/*"
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={1}
                constraintText="Only one image can be uploaded"
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Modal>

      {/* Details Modal */}
      <Modal
        onDismiss={() => setIsDetailsModalVisible(false)}
        visible={isDetailsModalVisible}
        footer={
          <Box float="right">
            <Button onClick={() => setIsDetailsModalVisible(false)}>Close</Button>
          </Box>
        }
        header="Spare Part Image Details"
      >
        {selectedImage && (
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Model">
              <Input value={selectedImage.model} readOnly />
            </FormField>
            <FormField label="Type">
              <Input value={selectedImage.type} readOnly />
            </FormField>
            <FormField label="Update Time">
              <Input value={selectedImage.updateTime} readOnly />
            </FormField>
            <FormField label="Updated By">
              <Input value={selectedImage.updatedBy} readOnly />
            </FormField>
            <FormField label="Approvers">
              <Input value={selectedImage.approvedBy || 'None'} readOnly />
            </FormField>
            <FormField label="Image">
              <img 
                src={selectedImage.imageUrl} 
                alt="Spare Part" 
                style={{maxWidth: '100%', maxHeight: '300px'}} 
              />
            </FormField>
          </SpaceBetween>
        )}
      </Modal>

      {/* Approve Modal */}
      <Modal
        onDismiss={() => setIsApproveModalVisible(false)}
        visible={isApproveModalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={() => setIsApproveModalVisible(false)}>
                Cancel
              </Button>
              <Button variant="primary" onClick={submitApproval}>
                Confirm Approval
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Approve Spare Part Image"
      >
        <SpaceBetween direction="vertical" size="l">
          {approveErrorText && (
            <Alert type="error">
              {approveErrorText}
            </Alert>
          )}
          {approveSuccessMessage && (
            <Alert type="success">
              {approveSuccessMessage}
            </Alert>
          )}
          {selectedImage && (
            <>
              <FormField label="Model">
                <Input value={selectedImage.model} readOnly />
              </FormField>
              <FormField label="Type">
                <Input value={selectedImage.type} readOnly />
              </FormField>
              <FormField label="Current Approvers">
                <Input 
                  value={selectedImage.approvedBy || 'None'} 
                  readOnly 
                />
              </FormField>
              <FormField label="Image">
                <img 
                  src={selectedImage.imageUrl} 
                  alt="Spare Part" 
                  style={{maxWidth: '100%', maxHeight: '300px'}} 
                />
              </FormField>
              <Box>
                <p>Are you sure you want to approve this spare part image?</p>
                <p>Your user ID ({user}) will be added to the approvers list.</p>
              </Box>
            </>
          )}
        </SpaceBetween>
      </Modal>
      {/* 添加删除确认对话框 */}
      <Modal
        onDismiss={() => setIsDeleteModalVisible(false)}
        visible={isDeleteModalVisible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button 
                variant="link" 
                onClick={() => setIsDeleteModalVisible(false)}
                disabled={deleteSuccess}
              >
                Cancel
              </Button>
              {/* <Button 
                variant="primary" 
                onClick={handleDelete}
                disabled={deleteSuccess}
              >
                Delete
              </Button> */}
            </SpaceBetween>
          </Box>
        }
        header="Delete Spare Part Image"
      >
        <SpaceBetween direction="vertical" size="l">
          {deleteError && (
            <Alert type="error">
              {deleteError}
            </Alert>
          )}
          {deleteSuccess && (
            <Alert type="success">
              Spare part image deleted successfully
            </Alert>
          )}
          {itemToDelete && !deleteSuccess && (
            <>
              <Box>
                <p>Are you sure you want to delete this spare part image?</p>
                <p>This action cannot be undone.</p>
              </Box>
              <FormField label="Model">
                <Input value={itemToDelete.model} readOnly />
              </FormField>
              <FormField label="Type">
                <Input value={itemToDelete.type} readOnly />
              </FormField>
              <FormField label="Current Approvers">
                <Input value={itemToDelete.approvedBy || 'None'} readOnly />
              </FormField>
              <FormField label="Image">
                <img 
                  src={itemToDelete.imageUrl} 
                  alt="Spare Part" 
                  style={{maxWidth: '100%', maxHeight: '300px'}} 
                />
              </FormField>
            </>
          )}
        </SpaceBetween>
      </Modal>
    </>
  );
};
import * as React from "react";
import { useState, useEffect } from 'react';
import Form from "@amzn/awsui-components-react/polaris/form";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import Container from "@amzn/awsui-components-react/polaris/container";
import Header from "@amzn/awsui-components-react/polaris/header";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Textarea from "@amzn/awsui-components-react/polaris/textarea";
import FileUpload from "@amzn/awsui-components-react/polaris/file-upload";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import ColumnLayout from "@amzn/awsui-components-react/polaris/column-layout";
import Multiselect from "@amzn/awsui-components-react/polaris/multiselect";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import { Tool } from '../../open-api/generated-src';
import ApiFactory from '../../open-api/ApiFactory';
import { getUser } from "../../auth/User";

import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import * as XLSX from 'xlsx';
import { Box, ProgressBar } from "@amzn/awsui-components-react";
import { createModuleResolutionCache } from "typescript";

export default () => {
    const [user, setUser] = useState('');
    const [errorText, setErrorText] = useState('');
    const [successText, setSuccessText] = useState('');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [isImportModalVisible, setIsImportModalVisible] = useState(false);
    const [importFile, setImportFile] = useState<File | null>(null);
    const [importErrorText, setImportErrorText] = useState('');
    const [importSuccessText, setImportSuccessText] = useState('');
    const [importProgress, setImportProgress] = useState(0);
    const [isImporting, setIsImporting] = useState(false);

    const [selectedMitigations, setSelectedMitigations] = React.useState<OptionDefinition[]>([
        {
            label: "1. 需要陪同 / 虚拟陪同",
            value: "1",
            description: "需要陪同或虚拟陪同"
        }
    ]);
    const mitigationOptions: OptionDefinition[] = [
        { label: "1. 需要陪同 / 虚拟陪同", value: "1", description: "需要陪同或虚拟陪同" },
        { label: "2. 不需要陪同", value: "2", description: "不需要陪同" },
        { label: "3. 关闭WiFi,并在设置中向安保人员展示", value: "3", description: "关闭WiFi并展示" },
        { label: "4. 关闭蓝牙,并在设置中向安保人员展示", value: "4", description: "关闭蓝牙并展示" },
        { label: "5. 对未使用的端口进行防篡改密封", value: "5", description: "密封未使用端口" },
        { label: "6. 对附件连接处进行防篡改密封", value: "6", description: "密封附件连接处" },
        { label: "7. 进入前和离开时检查电池仓", value: "7", description: "检查电池仓" },
        { label: "8. 检查是否有可移动存储介质", value: "8", description: "检查可移动存储" },
        { label: "9. 如使用WiFi适配器,遵循虚拟陪同流程", value: "9", description: "WiFi适配器虚拟陪同" },
        { label: "10. 检查任何废料或设备箱/容器", value: "10", description: "检查废料和容器" },
        { label: "11. 在进出检查点通过开关机演示设备正常工作", value: "11", description: "演示设备正常工作" },
        { label: "12. 如果电池仓有完整的安全封条,则无需对电池仓进行额外检查", value: "12", description: "电池仓安全封条检查" }
    ];
    
    const handleImport = async () => {
        if (!importFile) {
            setImportErrorText('请选择要导入的文件');
            return;
        }

        setIsImporting(true);
        setImportProgress(0);
        setImportErrorText('');
        setImportSuccessText('');

        try {
            const data = await readExcel(importFile);
            let successCount = 0;
            let failCount = 0;

            for (let i = 0; i < data.length; i++) {
                const row = data[i];
                try {
                    const newTool: Tool = {
                        uuid: 'fsdf', // 这个字段通常由后端生成，我们在前端设置为空字符串
                        category: row.category || '',
                        modelNumber: row.model_number || '',
                        maker: row.maker || '',
                        notes: row.notes || '',
                        mitigations: row.mitigations || '',
                        imageUrl: row.image_url || '',
                        createBy: user,
                        createAt: new Date().toISOString(),
                        approved: 'None',
                        definitions: '', // Excel中没有这个字段，设置为空字符串
                        detail: '', // Excel中没有这个字段，设置为空字符串
                        updateAt: '', 
                        updateBy: '', 
                        approvedBy: '', 
                    };

                    await Api.createTool({ tool: newTool });
                    successCount++;
                } catch (error) {
                    console.error("Error creating tool:", error);
                    failCount++;
                }

                setImportProgress(((i + 1) / data.length) * 100);
            }

            setImportSuccessText(`成功导入 ${successCount} 个工具，失败 ${failCount} 个`);
            setImportFile(null);
            setTimeout(() => {
                setIsImportModalVisible(false);
                setImportSuccessText('');
                setImportProgress(0);
            }, 2000);
        } catch (error) {
            console.error("Error reading Excel file:", error);
            setImportErrorText('读取 Excel 文件失败');
        } finally {
            setIsImporting(false);
        }
    };

    const readExcel = (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target?.result;
                const workbook = XLSX.read(data, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet);
                resolve(json);
            };
            reader.onerror = (error) => reject(error);
            reader.readAsBinaryString(file);
        });
    };
    const Api = ApiFactory();

    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            } catch(error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);

    const initialToolData: Partial<Tool> = {
        category: '',
        maker: '',
        modelNumber: '',
        definitions: '',
        mitigations: '',
        notes: '',
        createBy: user,
        approved: 'None',
        detail: ''
    };

    const [toolData, setToolData] = useState(initialToolData);

    const onChange = (attribute: keyof Tool, value: string) => {
        setToolData(prev => ({ ...prev, [attribute]: value }));
    };
    
    const convertFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };


    const handleSubmit = async () => {
        setErrorText('');
        setSuccessText('');
        try {
            if (!toolData.category || !toolData.maker || !toolData.modelNumber) {
                setErrorText('请填写必要信息');
                return;
            }

            if (!uploadedFile) {
                setErrorText('请上传工具图片');
                return;
            }

            // 这里应该先上传图片到S3，然后获取URL
            // 为了示例，我们假设直接获得了URL
            const fileContent = await convertFileToBase64(uploadedFile);

            const newTool: Tool = {
                ...toolData,
                uuid: 'abc', // 这个会由后端生成
                imageUrl: fileContent,
                createAt: new Date().toISOString(),
                createBy: user,
                approved: 'None',
                mitigations: selectedMitigations.map(option => option.label).join(', '),
            } as Tool;

            await Api.createTool({ tool: newTool });

            setSuccessText('工具创建成功');
            setToolData(initialToolData);
            setUploadedFile(null);
        } catch (error: any) {
            setErrorText(error?.response?.data?.message || '创建工具失败');
        }
    };

    return (
        <Form
            actions={
                <SpaceBetween direction="horizontal" size="xs">
                    <Button formAction="none" variant="link" href="#/tools">
                        取消
                    </Button>
                    <Button onClick={() => setIsImportModalVisible(true)}>
                        批量导入
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        创建
                    </Button>
                </SpaceBetween>
            }
            header={
                <Header variant="h1">创建 CICO Approved Tool</Header>
            }
        >
            <Container>
                <SpaceBetween direction="vertical" size="l">
                    {errorText && <Alert type="error">{errorText}</Alert>}
                    {successText && <Alert type="success">{successText}</Alert>}
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="类别" description="工具类别">
                                <Input
                                    value={toolData.category || ''}
                                    onChange={({ detail }) => onChange('category', detail.value)}
                                />
                            </FormField>
                            <FormField label="厂商" description="工具厂商">
                                <Input
                                    value={toolData.maker || ''}
                                    onChange={({ detail }) => onChange('maker', detail.value)}
                                />
                            </FormField>
                            <FormField label="型号" description="工具型号">
                                <Input
                                    value={toolData.modelNumber || ''}
                                    onChange={({ detail }) => onChange('modelNumber', detail.value)}
                                />
                            </FormField>
                            <FormField label="定义" description="工具定义">
                                <Textarea
                                    value={toolData.definitions || ''}
                                    onChange={({ detail }) => onChange('definitions', detail.value)}
                                />
                            </FormField>
                        </SpaceBetween>
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="缓解措施" description="工具缓解措施">
                                <Multiselect
                                    selectedOptions={selectedMitigations}
                                    onChange={({ detail }) =>
                                        setSelectedMitigations([...detail.selectedOptions])
                                    }
                                    options={mitigationOptions}
                                    placeholder="选择缓解措施"
                                />
                            </FormField>
                            <FormField label="注意事项" description="使用注意事项">
                                <Textarea
                                    value={toolData.notes || ''}
                                    onChange={({ detail }) => onChange('notes', detail.value)}
                                />
                            </FormField>
                            <FormField label="详细信息" description="其他详细信息">
                                <Textarea
                                    value={toolData.detail || ''}
                                    onChange={({ detail }) => onChange('detail', detail.value)}
                                />
                            </FormField>
                        </SpaceBetween>
                    </ColumnLayout>
                    <FormField label="上传图片" description="上传工具图片">
                        <FileUpload
                            onChange={({ detail }) => setUploadedFile(detail.value[0])}
                            value={uploadedFile ? [uploadedFile] : []}
                            i18nStrings={{
                                uploadButtonText: e => e ? "选择文件" : "选择文件",
                                dropzoneText: e => e ? "拖放文件以上传" : "拖放文件以上传",
                                removeFileAriaLabel: e => `移除文件 ${e + 1}`,
                                limitShowFewer: "显示较少",
                                limitShowMore: "显示更多",
                                errorIconAriaLabel: "错误"
                            }}
                            accept="image/*"
                            multiple={false}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={1}
                            constraintText="只能上传一张图片"
                        />
                    </FormField>
                </SpaceBetween>
            </Container>
            <Modal
            onDismiss={() => setIsImportModalVisible(false)}
            visible={isImportModalVisible}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button onClick={() => setIsImportModalVisible(false)} disabled={isImporting}>取消</Button>
                        <Button variant="primary" onClick={handleImport} disabled={isImporting}>
                            {isImporting ? "导入中..." : "导入"}
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            header="批量导入工具"
        >
            <SpaceBetween direction="vertical" size="l">
                {importErrorText && <Alert type="error">{importErrorText}</Alert>}
                {importSuccessText && <Alert type="success">{importSuccessText}</Alert>}
                <FormField label="选择Excel文件">
                    <FileUpload
                        onChange={({ detail }) => setImportFile(detail.value[0])}
                        value={importFile ? [importFile] : []}
                        accept=".xlsx,.xls"
                        multiple={false}
                        i18nStrings={{
                            uploadButtonText: e => e ? "选择文件" : "选择文件",
                            dropzoneText: e => e ? "拖放文件以上传" : "拖放文件以上传",
                            removeFileAriaLabel: e => `移除文件 ${e + 1}`,
                            limitShowFewer: "显示较少",
                            limitShowMore: "显示更多",
                            errorIconAriaLabel: "错误"
                        }}
                    />
                </FormField>
                {isImporting && (
                    <ProgressBar 
                        value={importProgress}
                        label="导入进度"
                        description={`已处理 ${Math.round(importProgress)}%`}
                    />
                )}
            </SpaceBetween>
        </Modal>
        </Form>
    );
};
import * as React from "react";
import { useState, useEffect } from 'react';
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import SpaceBetween from "@amzn/awsui-components-react/polaris/space-between";
import Button from "@amzn/awsui-components-react/polaris/button";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import FormField from "@amzn/awsui-components-react/polaris/form-field";
import Input from "@amzn/awsui-components-react/polaris/input";
import Alert from "@amzn/awsui-components-react/polaris/alert";
import Badge from "@amzn/awsui-components-react/polaris/badge";
import ApiFactory from '../../open-api/ApiFactory';
import { Tool } from '../../open-api/generated-src';
import Select from "@amzn/awsui-components-react/polaris/select";
import { AxiosRequestConfig } from "axios";
import { getUser } from "../../auth/User";

export default () => {
    const [selectedItems, setSelectedItems] = useState<Tool[]>([]);
    const [tools, setTools] = useState<Tool[]>([]);
    const [user, setUser] = useState('');
    const [loading, setLoading] = useState<boolean>(true);
    const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
    const [selectedTool, setSelectedTool] = useState<Tool | null>(null);
    const [errorText, setErrorText] = useState('');
    const [deleteErrorText, setDeleteErrorText] = useState('');
    const [deleteSuccessText, setDeleteSuccessText] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [pagesCount, setPagesCount] = useState(1);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | null>(null);
    const [approvalStatus, setApprovalStatus] = useState('');
    const [isApproving, setIsApproving] = useState(false);
    const [approvalErrorText, setApprovalErrorText] = useState('');
    const [approvalSuccessText, setApprovalSuccessText] = useState('');

    const [isBulkUpdateModalVisible, setIsBulkUpdateModalVisible] = useState(false);
    const [bulkUpdateStatus, setBulkUpdateStatus] = useState('');
    const [isBulkUpdating, setIsBulkUpdating] = useState(false);
    const [bulkUpdateErrorText, setBulkUpdateErrorText] = useState('');
    const [bulkUpdateSuccessText, setBulkUpdateSuccessText] = useState('');


    const ToolApi = ApiFactory();

    useEffect(() => {
        fetchTools();
    }, []);
    
    useEffect(() => {
        (async () => {
            try {
                setUser(await getUser());
            }
            catch (error: any) {
                setUser('Unknown User')
            }
        })();
    }, []);
    
    const handleBulkUpdate = async () => {
        if (selectedItems.length === 0 || !bulkUpdateStatus) return;

        setIsBulkUpdating(true);
        setBulkUpdateErrorText('');
        setBulkUpdateSuccessText('');

        try {
            for (const tool of selectedItems) {
                const updatedTool: Tool = {
                    ...tool,
                    approved: bulkUpdateStatus,
                    approvedBy: user, // Replace with actual user name or ID
                    updateAt: new Date().toISOString()
                };
                await ToolApi.updateTool(tool.uuid, { tool: updatedTool });
            }

            setBulkUpdateSuccessText(`Successfully updated ${selectedItems.length} tools`);
            fetchTools(currentPageIndex); // 重新获取当前页面的数据
            setSelectedItems([]);
            setBulkUpdateStatus('');
            setTimeout(() => {
                setIsBulkUpdateModalVisible(false);
                setBulkUpdateSuccessText('');
            }, 2000);
        } catch (error) {
            console.error("Error updating tools:", error);
            setBulkUpdateErrorText('Failed to update tools');
        } finally {
            setIsBulkUpdating(false);
        }
    };
    
    const handleApprove = async () => {
        if (!selectedTool || !approvalStatus) return;
    
        setIsApproving(true);
        setApprovalErrorText('');
        setApprovalSuccessText('');
    
        try {
            const updatedTool: Tool = {
                ...selectedTool,
                approved: approvalStatus,
                approvedBy: user, // Replace with actual user name or ID
                updateAt: new Date().toISOString()
            };
    
            await ToolApi.updateTool(selectedTool.uuid, { tool: updatedTool });
            setApprovalSuccessText('Tool approval status updated successfully');
            
            // 更新 selectedTool 状态
            setSelectedTool(updatedTool);
            
            // 更新 tools 列表中的对应工具
            setTools(prevTools => prevTools.map(tool => 
                tool.uuid === updatedTool.uuid ? updatedTool : tool
            ));
    
            // 重置 approvalStatus
            setApprovalStatus('');
    
        } catch (error) {
            console.error("Error updating tool approval status:", error);
            setApprovalErrorText('Failed to update tool approval status');
        } finally {
            setIsApproving(false);
        }
    };


    const fetchTools = async (pageIndex: number = 1) => {
        setLoading(true);
        try {
            const limit = 800; // 每页显示的项目数
            let response;
    
            if (pageIndex === 1) {
                // 第一页，不需要 lastEvaluatedKey
                response = await ToolApi.listTools(undefined, limit);
            } else {
                // 非第一页，使用 lastEvaluatedKey
                response = await ToolApi.listTools(lastEvaluatedKey as string, limit);
            }
    
            setTools(response.data.tools);
            setLastEvaluatedKey(response.data.paginationInfo?.lastEvaluatedKey || null);
            setCurrentPageIndex(pageIndex);
            
            // 如果有 lastEvaluatedKey，说明还有下一页
            if (response.data.paginationInfo?.lastEvaluatedKey) {
                setPagesCount(prevCount => Math.max(prevCount, pageIndex + 1));
            } else {
                setPagesCount(pageIndex);
            }
        } catch (error) {
            console.error("Error fetching tools:", error);
            setErrorText('Failed to fetch tools');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage: number) => {
        fetchTools(newPage);
    };

    const renderMitigations = (mitigations: string) => {
        const mitigationList = mitigations.split(', ');
        return (
            <SpaceBetween direction="horizontal" size="xs">
                {mitigationList.map((mitigation, index) => (
                    <Badge key={index} color="blue">{mitigation}</Badge>
                ))}
            </SpaceBetween>
        );
    };

    const handleViewDetails = (item: Tool) => {
        setSelectedTool(item);
        setIsDetailsModalVisible(true);
    };

    const handleDelete = async () => {
        if (!selectedTool) return;
    
        try {
            await ToolApi.deleteTool(selectedTool.uuid);
            setDeleteSuccessText('Tool deleted successfully');
            fetchTools(currentPageIndex); // 重新获取当前页面的数据
            setTimeout(() => {
                setIsDeleteModalVisible(false);
                setIsDetailsModalVisible(false);
                setDeleteSuccessText('');
            }, 2000);
        } catch (error) {
            console.error("Error deleting tool:", error);
            setDeleteErrorText('Failed to delete tool');
        }
    };
    const createToolButton = (
        <Button variant="primary" href="#/cat/create">
            Create Tool
        </Button>
    );

    const columnDefinitions = [
        {
            id: "category",
            header: "类别",
            cell: item => item.category,
            sortingField: "category",
            maxWidth: 50
        },
        {
            id: "maker",
            header: "厂商",
            cell: item => item.maker,
            sortingField: "maker",
            maxWidth: 50
        },
        {
            id: "modelNumber",
            header: "型号",
            cell: item => item.modelNumber,
            sortingField: "modelNumber",
            maxWidth: 50
        },
        {
            id: "approved",
            header: "批准状态",
            cell: item => (
                <Badge color={item.approved === 'Approved' ? 'green' : (item.approved === 'Rejected' ? 'red' : 'grey')}>
                    {item.approved}
                </Badge>
            ),
            sortingField: "approved"
        },
        {
            id: "createAt",
            header: "创建时间",
            cell: item => new Date(item.createAt).toLocaleString(),
            sortingField: "createAt"
        },
        {
            id: "actions",
            header: "操作",
            cell: item => (
                <Button
                    variant="inline-link"
                    onClick={() => handleViewDetails(item)}
                >
                    查看详情
                </Button>
            )
        }
    ];

    return (
        <>
            <Table
                columnDefinitions={columnDefinitions}
                items={tools}
                loading={loading}
                loadingText="Loading tools"
                selectionType="multi"
                selectedItems={selectedItems}
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
                header={
                    <Header
                        counter={`(${tools.length})`}
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                {createToolButton}
                                <Button 
                                    onClick={() => setIsBulkUpdateModalVisible(true)}
                                    disabled={selectedItems.length === 0}
                                >
                                    批量更新
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        CICO Approved Tools
                    </Header>
                }
                filter={
                    <TextFilter
                        filteringPlaceholder="查找工具"
                        filteringText=""
                    />
                }
                pagination={
                    <Pagination
                        currentPageIndex={currentPageIndex}
                        onChange={({ detail }) => {
                            fetchTools(detail.currentPageIndex);
                        }}
                        pagesCount={pagesCount}
                    />
                }
            />

            <Modal
                onDismiss={() => setIsDetailsModalVisible(false)}
                visible={isDetailsModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setIsDetailsModalVisible(false)}>关闭</Button>
                            <Button variant="primary" onClick={() => setIsDeleteModalVisible(true)}>删除</Button>
                            <Button 
                                variant="primary" 
                                onClick={handleApprove}
                                // disabled={isApproving || !approvalStatus || approvalStatus === selectedTool?.approved}
                            >
                                {isApproving ? "处理中..." : "批复"}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="工具详情"
            >
                {selectedTool && (
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="类别">
                            <Input value={selectedTool.category} readOnly />
                        </FormField>
                        <FormField label="厂商">
                            <Input value={selectedTool.maker} readOnly />
                        </FormField>
                        <FormField label="型号">
                            <Input value={selectedTool.modelNumber} readOnly />
                        </FormField>
                        <FormField label="定义">
                            <Input value={selectedTool.definitions} readOnly />
                        </FormField>
                        <FormField label="缓解措施">
                            {renderMitigations(selectedTool.mitigations)}
                        </FormField>
                        <FormField label="注意事项">
                            <Input value={selectedTool.notes} readOnly />
                        </FormField>
                        <FormField label="创建时间">
                            <Input value={new Date(selectedTool.createAt).toLocaleString()} readOnly />
                        </FormField>
                        <FormField label="创建人">
                            <Input value={selectedTool.createBy} readOnly />
                        </FormField>
                        <FormField label="更新时间">
                            <Input value={selectedTool.updateAt ? new Date(selectedTool.updateAt).toLocaleString() : 'N/A'} readOnly />
                        </FormField>
                        <FormField label="更新人">
                            <Input value={selectedTool.updateBy || 'N/A'} readOnly />
                        </FormField>
                        <FormField label="批准状态">
                            <Select
                                selectedOption={
                                    approvalStatus 
                                        ? { label: approvalStatus, value: approvalStatus }
                                        : { label: selectedTool.approved, value: selectedTool.approved }
                                }
                                onChange={({ detail }) => {
                                    const newApprovalStatus = detail.selectedOption.value || ''; // 确保总是字符串
                                    setApprovalStatus(newApprovalStatus);
                                    // 更新 selectedTool 的 approved 状态
                                    if (selectedTool) {
                                        setSelectedTool({
                                            ...selectedTool,
                                            approved: newApprovalStatus
                                        });
                                    }
                                }}
                                options={[
                                    { label: "Approved", value: "Approved" },
                                    { label: "Rejected", value: "Rejected" },
                                    { label: "Pending", value: "Pending" }
                                ]}
                            />
                        </FormField>
                        <FormField label="批准人">
                            <Input value={selectedTool.approvedBy || 'N/A'} readOnly />
                        </FormField>
                        <FormField label="详细信息">
                            <Input value={selectedTool.detail || 'N/A'} readOnly />
                        </FormField>
                        <FormField label="工具图片">
                            <img 
                                src={selectedTool.imageUrl} 
                                alt="Tool" 
                                style={{maxWidth: '100%', maxHeight: '300px'}} 
                            />
                        </FormField>
                        
                        {approvalErrorText && <Alert type="error">{approvalErrorText}</Alert>}
                        {approvalSuccessText && <Alert type="success">{approvalSuccessText}</Alert>}
                    </SpaceBetween>
                )}
            </Modal>

            <Modal
                onDismiss={() => setIsDeleteModalVisible(false)}
                visible={isDeleteModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setIsDeleteModalVisible(false)}>取消</Button>
                            <Button variant="primary" onClick={handleDelete}>确认删除</Button>
                        </SpaceBetween>
                    </Box>
                }
                header="确认删除"
            >
                <SpaceBetween direction="vertical" size="l">
                    {deleteErrorText && <Alert type="error">{deleteErrorText}</Alert>}
                    {deleteSuccessText && <Alert type="success">{deleteSuccessText}</Alert>}
                    <p>您确定要删除这个工具吗？此操作不可撤销。</p>
                    {selectedTool && (
                        <>
                            <FormField label="类别">
                                <Input value={selectedTool.category} readOnly />
                            </FormField>
                            <FormField label="厂商">
                                <Input value={selectedTool.maker} readOnly />
                            </FormField>
                            <FormField label="型号">
                                <Input value={selectedTool.modelNumber} readOnly />
                            </FormField>
                        </>
                    )}
                </SpaceBetween>
            </Modal>
            <Modal
                onDismiss={() => setIsBulkUpdateModalVisible(false)}
                visible={isBulkUpdateModalVisible}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => setIsBulkUpdateModalVisible(false)}>取消</Button>
                            <Button 
                                variant="primary" 
                                onClick={handleBulkUpdate}
                                disabled={isBulkUpdating || !bulkUpdateStatus}
                            >
                                {isBulkUpdating ? "更新中..." : "确认更新"}
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
                header="批量更新工具"
            >
                <SpaceBetween direction="vertical" size="l">
                    {bulkUpdateErrorText && <Alert type="error">{bulkUpdateErrorText}</Alert>}
                    {bulkUpdateSuccessText && <Alert type="success">{bulkUpdateSuccessText}</Alert>}
                    <p>您正在批量更新 {selectedItems.length} 个工具的批准状态。</p>
                    <FormField label="批准状态">
                        <Select
                            selectedOption={
                                bulkUpdateStatus 
                                    ? { label: bulkUpdateStatus, value: bulkUpdateStatus }
                                    : null
                            }
                            onChange={({ detail }) => {
                                setBulkUpdateStatus(detail.selectedOption.value || '');
                            }}
                            options={[
                                { label: "Approved", value: "Approved" },
                                { label: "Rejected", value: "Rejected" },
                                { label: "Pending", value: "Pending" }
                            ]}
                        />
                    </FormField>
                </SpaceBetween>
            </Modal>

            {errorText && <Alert type="error">{errorText}</Alert>}
        </>
    );
};